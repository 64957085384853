import React from 'react';
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Profile } from '../';
import { NavItemType } from '../../types';
import { Link } from 'react-scroll';

interface NavigationProps {
    isOpen: boolean;
    items: Array<NavItemType>;
    toggle: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ isOpen, items, toggle }) => (
    <Navbar id="sideNav" color="primary" container={false} expand="lg" fixed="top" dark>
        <NavbarBrand>
            <Profile />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
                {items.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink tag={Link} duration={500} to={item.href} spy smooth>
                            {item.label}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
        </Collapse>
    </Navbar>
);

export default Navigation;

{
    /* <Nav.Link as={Link} duration={500} spy smooth to={props.to}>
{props.children}
</Nav.Link> */
}
