import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResumeSection, ResumeSectionContent } from '../../components';
import { AboutType } from '../../types';

interface AboutProps {
    data: AboutType;
}

const About: React.FC<AboutProps> = ({ data }) => (
    <ResumeSection id="about">
        <ResumeSectionContent>
            <h1 className="mb-0">
                {data.firstName}
                <span className="text-primary">{data.lastName}</span>
            </h1>
            <div className="subheading mb-5">
                {/* {data.addressLine1} · {data.addressLine2} · {data.townCity}, {data.postcode} ·{' '} */}
                {data.contactNumber} · <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
            <p className="lead mb-5">{data.bio}</p>
            {data.socials && (
                <div className="social-icons">
                    {data.socials.map((social, index) => (
                        <a key={index} className="social-icon" href={social.href}>
                            <FontAwesomeIcon icon={social.icon} />
                        </a>
                    ))}
                </div>
            )}
        </ResumeSectionContent>
    </ResumeSection>
);

export default About;
