import React from 'react';
import classnames from 'classnames';

interface ResumeSectionContentProps extends React.HTMLAttributes<HTMLElement> {}

const ResumeSectionContent: React.FC<ResumeSectionContentProps> = ({ className, ...props }) => {
    const classNames = classnames('resume-section-content', className);

    return <div className={classNames} {...props} />;
};

export default ResumeSectionContent;
