import { ExperienceType } from '../../types';

const experience: Array<ExperienceType> = [
    {
        title: 'Software Engineer',
        companyName: 'giffgaff',
        description:
            'PHP (Laravel), TypeScript (React, React Native, Redux, GraphQL, Styled-components and Node), SASS, MySQL, Docker',
        startDate: 'September 2019',
        endDate: 'Present'
    },
    {
        title: 'Full Stack Developer',
        companyName: 'Sky',
        description: 'PHP (Laravel), JavaScript (React, Redux and Node), CSS, MySQL, Docker',
        startDate: 'September 2017',
        endDate: 'September 2019'
    },
    {
        title: 'Web Developer',
        companyName: 'Cisco',
        description: 'PHP, HTML, CSS, MySQL',
        startDate: 'December 2015',
        endDate: 'September 2017'
    },
    {
        title: 'Web Developer',
        companyName: 'Portcullis Computer Security Ltd',
        description: 'PHP, HTML, CSS, MySQL',
        startDate: 'August 2014',
        endDate: 'December 2015'
    }
];

export default experience;
