import React from 'react';
import data from '../../utilities/constants/about';
import About from './About';

interface AboutContainerProps {}

const AboutContainer: React.FC<AboutContainerProps> = () => {
    return <About data={data} />;
};

export default AboutContainer;
