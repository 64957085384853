import { EducationType } from '../../types';

const education: Array<EducationType> = [
    {
        school: 'Buckinghamshire New University',
        degree: 'Bachelor of Science',
        fieldOfStudy: 'Computing',
        startDate: '2011',
        endDate: '2014',
        grade: '2:1'
    },
    {
        school: 'The Douay Martyrs School',
        degree: 'A Level',
        fieldOfStudy: 'Information and Communications Technology',
        startDate: '2008',
        endDate: '2010',
        grade: 'B'
    }
];

export default education;
