import { AboutType } from '../../types';

const about: AboutType = {
    firstName: 'Andrew',
    lastName: 'Dyer',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    postcode: '',
    contactNumber: '',
    email: '',
    bio:
        "Experienced full-stack developer from London with a passion to create! Skilled in PHP, MySQL, and TypeScript. Strong engineering professional with a bachelor's degree focused in computing from Buckinghamshire New University.",
    socials: [
        {
            href: 'https://www.linkedin.com/in/andrewndyer/',
            icon: ['fab', 'linkedin-in']
        },
        {
            href: 'https://github.com/andrewdyer',
            icon: ['fab', 'github']
        }
    ]
};

export default about;
