import React from 'react';
import data from '../../utilities/constants/education';
import Education from './Education';

interface EducationContainerProps {}

const EducationContainer: React.FC<EducationContainerProps> = () => {
    return <Education items={data} />;
};

export default EducationContainer;
