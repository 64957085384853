import React from 'react';
import data from '../../utilities/constants/experience';
import Experience from './Experience';

interface ExperienceContainerProps {}

const ExperienceContainer: React.FC<ExperienceContainerProps> = () => {
    return <Experience items={data} />;
};

export default ExperienceContainer;
