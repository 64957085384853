import React, { useState } from 'react';
import navigation from '../../utilities/constants/navigation';
import Navigation from './Navigation';

interface NavigationContainerProps {}

const NavigationContainer: React.FC<NavigationContainerProps> = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return <Navigation isOpen={isOpen} items={navigation} toggle={toggle} />;
};

export default NavigationContainer;
