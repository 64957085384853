import React from 'react';
import classnames from 'classnames';

interface ResumeSectionProps extends React.HTMLAttributes<HTMLElement> {}

const ResumeSection: React.FC<ResumeSectionProps> = ({ className, ...props }) => {
    const classNames = classnames('resume-section', 'element', className);

    return <section className={classNames} {...props} />;
};

export default ResumeSection;
