import React from 'react';
import image from '../../assets/img/profile.jpg';

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => (
    <>
        <span className="d-block d-lg-none">Andrew Dyer</span>
        <span className="d-none d-lg-block">
            <img
                className="img-fluid img-profile rounded-circle mx-auto mb-2"
                src={image}
                alt="..."
            />
        </span>
    </>
);

export default Profile;
