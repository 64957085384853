import React from 'react';
import classnames from 'classnames';
import { ResumeSection, ResumeSectionContent } from '../../components';
import { ExperienceType } from '../../types';

interface ExperienceProps {
    items: Array<ExperienceType>;
}

const Experience: React.FC<ExperienceProps> = ({ items }) => (
    <ResumeSection id="experience">
        <ResumeSectionContent>
            <h2 className="mb-5">Experience</h2>
            {items.map((item, index, items) => {
                const isLastItem = index + 1 !== items.length;

                const classNames = classnames(
                    'd-flex flex-column flex-md-row justify-content-between',
                    {
                        'mb-5': isLastItem
                    }
                );

                return (
                    <div key={index} className={classNames}>
                        <div className="flex-grow-1">
                            <h3 className="mb-0">{item.title}</h3>
                            <div className="subheading mb-3">{item.companyName}</div>
                            <p>{item.description}</p>
                        </div>
                        <div className="flex-shrink-0">
                            <span className="text-primary">
                                {item.startDate} - {item.endDate}
                            </span>
                        </div>
                    </div>
                );
            })}
        </ResumeSectionContent>
    </ResumeSection>
);

export default Experience;
