import { NavItemType } from '../../types';

const navigation: Array<NavItemType> = [
    {
        href: 'about',
        label: 'About'
    },
    {
        href: 'experience',
        label: 'Experience'
    },
    {
        href: 'education',
        label: 'Education'
    }
];

export default navigation;
