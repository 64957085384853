import React from 'react';
import { Navigation } from './components';
import { About, Education, Experience } from './sections';

interface AppProps {}

const App: React.FC<AppProps> = () => (
    <div id="page-top">
        <Navigation />
        <div className="container-fluid p-0">
            <About />
            <hr className="m-0" />
            <Experience />
            <hr className="m-0" />
            <Education />
        </div>
    </div>
);

export default App;
